<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
.fontsize14px{
  font-size: 14px !important;
}
.fontsize13px{
  font-size: 13px !important;
}
.fontsize18px{
  font-size: 18px !important;
}
.fontsize20px{
  font-size: 20px !important;
}
.fontsize25px {
  font-size: 25px !important;
}
.fontsize30px {
  font-size: 30px !important;
}
.fontsize40px {
  font-size: 40px !important;
}
.fontsize50px {
  font-size: 50px !important;
}
.CardColor {
  background: linear-gradient(to bottom, #f7f8ff 0%, #ffffff 90%) !important;
}
// .text-width {
//   width: 1300px !important;
// }
</style>
