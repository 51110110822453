<template>
  <v-app>
    <v-card class="elevation-0 CardColor" >
      <div class=" grey--text pa-16" :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'fontsize30px' :'fontsize50px'">Privacy policy</div>
      l<v-row :class="$vuetify.breakpoint.lg ? 'pl-16 pt-0 mt-n16' : 'pa-16 mt-n16 pt-0  '">
        <v-col
          cols="12"
          md="12"
          xs="12"
          sm="12"
          :class="$vuetify.breakpoint.lg ? 'pt-5 px-16' : 'ma-0'"
          v-for="(item, index) in PrivacyData"
            :key="index"
        >
        
        <ul>
          <li class="text-width text-justify fontsize13px" :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'fontsize13px' :'fontsize20px'">{{ item.text }}</li>
        </ul>
          <!-- <div class="fontsize40px grey--text">Privacy policy</div>
          <div
            v-for="(item, index) in PrivacyData"
            :key="index"
            :class="$vuetify.breakpoint.lg ? 'px-16' : 'px-0'"
          >
            <v-icon class="mr-2" x-small color="grey">mdi-circle</v-icon>

            <span class="text-justify text-width">{{ item.text }}</span>
          </div> -->
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    PrivacyData: [
      {
        text: "I agree that this App at different points in the navigation needs disclosure of certain user information such as Name, Mobile Number, Email Address, Location and such personal details.",
      },
      {
        text: "These details are collected primarily for the purposes of allowing users access to certain functions and contents of the App as configured by the App Administrator based on different roles users may take at different points in time.",
      },
      {
        text: "The App administrator has access to these information and may use the same for purposes of sending more targeted content to you through this App itself or alternately through other medium.",
      },
      {
        text: "If you feel uncomfortable sharing these information, you are requested to not go ahead with the download and install of this App.",
      },
    ],
  }),
};
</script>
